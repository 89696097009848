.App {
  text-align: center;
  overflow-x: hidden;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#header {
  height: 560px;
  background-image: url('./home-page-image.jpg');
  background-size: cover;
  position: relative;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

#footer {
  height: 560px;
  background-image: url('./sub-image.jpg');
  background-size: cover;
  position: relative;
  -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
}

#introduction {
  height: 260px;
  background-color: #ffffff;
}

#services {
  margin-top: 0px;
  padding-top: 90px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-left: 2px;
  margin-right: 2px;
}

#services2 {
  margin-top: 0px;
  padding-top: 90px;
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 0px;
}

.container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.headline {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
}

.siteTitle {
  color: #dddddd;
  font-size: 2.0em;
  font-family: 'Arapey', serif;
}

.siteSubTitle {
  color: #94c940;
  font-weight: lighter;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.0em;
}

.siteSubTitle2 {
  color: #94c940;
  font-weight: lighter;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.75em;
}

.bodyText {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.77em;
  margin-top: 30px;
}

.bodyText > h3 {
  font-size: 1.0em;
  font-weight: 900;
  padding-bottom: 20px;
}

.bodyText > span {
  font-size: 0.8em;
  font-weight: 100;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333333;
}

.App-link {
  color: #94c940;
}

.filter-white {
  filter: invert(90%)
}

.contactDetails {
  padding-left: 20px;
  color: #cccccc;
  font-size: 1.2em;
  font-weight: 100;
}

.contactDetails > a {
  text-decoration: none;
  color: #cccccc;
}

.contactDetails > a:hover {
  text-decoration: none;
  color: #94c940;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
